
export default {
    LOGIN: {
        TITLE: 'Login',
        MSG: 'Zugriff auf dein Wallet.',
        EMAIL: 'Email',
        PASSWORD: 'Password',
        LOGIN: 'Login',
        SIGN_UP: 'Registrieren',
        FORGOT_PASSWORD: 'Passwort vergessen?',
        CONFIRM_PASSWORD: 'Passwort bestätigen',
        CREATE_ACCOUNT: 'Account erstellen',
        ALREADY_ACCOUNT_LOGIN: 'Haben Sie schon einen Account? Login',
        REMEMBER_ME: 'Eingeloggt bleiben',
    },
    CREDENTIALS: {
        TITLE: 'Meine Credentials',
        SEARCH: 'Suche...'
    },
    RECEIVE_CREDENTIALS: {
        CONFIRM_TITLE: 'Verbindungsanfrage',
        CONFIRM_CONNECTION_WITH: 'Verbindung bestätigen, mit',
        CONFIRM: 'Bestätigen',
        REJECT: 'Ablehnen',
        RECEIVED_CREDENTIALS_TITLE: 'Erhaltene Credentials',
        RECEIVED_CREDENTIALS_SUBTITLE: 'Füge die Credential(s) deiner Wallet hinzu, indem du auf "Akzeptieren" klickst',
        ACCEPT: 'Akzeptieren'
    },
    BACK: 'Zurück',
    MENU: {
        CREDENTIALS: 'Credentials',
        CONNECTIONS: 'Verbindungen',
        SETTINGS: 'Einstellungen',
        LOGOUT: 'Logout'
    },
    CREDENTIAL: {
        ID: 'Credential Id',
        TITLE: 'Titel',
        ISSUER: 'Aussteller',
        STATUS: 'Status',
        ISSUED_ON: 'Ausgestellt am',
        EXPIRE: 'Ablaufdatum',
        VALID_FROM: 'Gültig ab',
        FIRST_NAME: 'Vorname',
        GIVEN_NAME: 'Vorname',
        LAST_NAME: 'Nachname',
        FAMILY_NAME: 'Nachname',
        GENDER: 'Geschlecht',
        DATE_OF_BIRTH: 'Geburtsdatum',
        ADDRESS: 'Adresse',
        STREET_ADDRESS: 'Straße',
        POSTAL_CODE: 'Postleitzahl',
        LOCALITY: 'Ort',
        COUNTRY_NAME: 'Land',
        FAMILY_STATUS: 'Familienstand',
        IDENTIFICATION_NUMBER: 'Identifikationsnummer',
        DID: 'DID',
        NAME_AND_FAMILY_NAME_AT_BIRTH: 'Name und Familienname bei Geburt',
        NATIONALITY: 'Nationalität',
        PLACE_OF_BIRTH: 'Geburtsort',
        PLACE_OF_BIRTH_COUNTRY: 'Land (Geburtsort)',
        DATE_OF_BIRTH_LOCALITY: 'Stadt (Geburtsort)',
        PERSONAL_IDENTIFIER: 'Persönlicher Identifier',
        VACCINE_OR_PROFYLAXIS: 'Impfung oder Prophylaxe',
        VACCINE_MEDICINAL_PRODUCT: 'Impfstoff',
        DOSE_NUMBER: 'Dosis Anzahl',
        DATE_OF_VACCINATION: 'Datum der Impfung',
        ADMINISTERING_CENTER: 'Impfzentrum',
        COUNTRY_OF_VACCINATION: 'Land der Impfung',
        PROGRAM_NAME: 'Programm Name',
        DOMAIN: 'Domain',
        GRADING_SCHEMA: 'Bewertungsschema',
        TYPE: {
            VerifiableId: 'Verifizierbares ID Dokument',
            VerifiableDiploma: 'Verifizierbares Universitätsdiplom',
            VerifiableVaccinationCertificate: 'Verifizierbares Impfzertifikat',
            ProofOfResidence: 'Nachweis des Wohnsitzes',
            ParticipantCredential: "Gaia-X Mitgliedschafts Zertifikat",
            VerifiableAttestation: "Verifiable attestation",
            EuropeanBankIdentity: "Europäische Bank Identität",
            OpenBadgeCredential: "Open Badge V3",
            Europass: "Europass"
        },
        REGISTRATION_NUMBER: 'Firmennummer',
        LEGALLY_BINDING_NAME: 'Firmenname',
        JURISDICTION: 'Hauptsitz',
        COUNTRY: 'Land',
        LEI_CODE: 'LEI code',
        ETH_ADDRESS: 'ETH Address',
        PARENT_ORGANIZATION: 'Dach-Gesellschaft',
        SUB_ORGANIZATION: 'Tochter-Gesellschaft',
        LEARNING_ACHIEVEMENT: 'Learning achievement',
        ECTS_CREDIT_POINTS: 'ECTS credit points',
        DEFINITION: 'Definition',
        EQFL_LEVEL: 'EQFL level',
        NQFL_LEVEL: 'NQFL level',
        LOCATION: 'Location',
        STARTED_AT_TIME: 'Started at time',
        ENDED_AT_TIME: 'Ended at time',
        HOME_PAGE: 'Home page',
        ADDITIONAL_NOTE: 'Additional note',
        WORKLOAD: 'Workload',
        AWARDING_BODY: 'Awarding body',
        MAXIMUM_DURATION: 'Maximum duration',
        VOLUME_OF_LEARNING: 'Volume of learning',
        IS_PARTIAL_QUALIFICATION: 'Is Partial Qualification',
        LANGUAGE: 'Language',
        ALTERNATIVE_LABEL: 'Alternative label',
        SUPPLEMENTARY_DOCUMENT: 'Supplementary document',
        MODE: 'Mode',
        ISCEDF_CODE: 'ISCEDF code',
        IDENTIFIER: 'Identifier',
        ACHIEVED: 'Achieved',
        WAS_DERIVED_FROM: 'Was derived from',
        WAS_INFLUENCED_BY: 'Was influenced by',
        WAS_AWARDED_BY: 'Was awarded by',
        SPECIFIED_BY: 'Specified by',
        LEARNING_OUTCOME: 'Learning outcome',
        AWARDING_OPPORTUNITY: 'Awarding opportunity',
        LEARNING_ACTIVITY_TYPE: 'Learning activity type',
        DIRECTED_BY: 'Directed by',
        GRADE: 'Grade',
        AWARDING_DATE: 'Awarding date',
        AWARDING_LOCATION: 'Awarding location',
        DESCRIPTION: 'Description',
        ISSUED_DATE: 'Issued date',
        ENTITLEMENT_TYPE: 'Entitlement type',
        LIMIT_ORGANIZATION: 'Limit organization',
        LIMIT_JURISDICTION: 'Limit jurisdiction',
        SCHEME_ID: 'Scheme Id',
        VALUE: 'Value',
        ASSESSED_BY: 'Assessed by',
        TEACHES: 'Teaches',
        HAS_PART: 'Has part',
        ENTRY_REQUIREMENTS_NOTE: 'Entry requirements note',
        NAME: 'Name',
        LEARNING_OUTCOME_TYPE: 'Learning outcome type',
        REUSABILITY_LEVEL: 'Reusability level',
        RELATED_SKILL: 'Related skill',
        RELATED_ESCO_SKILL: 'Related ESCO skill',
        LEARNING_OPPORTUNITY_TYPE: 'Learning opportunity type',
        LEARNING_OUTCOME_DESCRIPTION: 'Learning outcome description',
        EDUCATION_SUBJECT: 'Education subject',
        CREDIT_POINTS: 'Credit points',
        EDUCATION_LEVEL: 'Education level',
        LEARNING_SETTING: 'Learning setting',
        TARGET_GROUP: 'Target group',
        LEARNING_ACTIVITY_SPECIFICATION: 'Learning activity specification',
        ASSESSMENT_SPECIFICATION: 'Assessment specification',
        ENTITLEMENT_SPECIFICATION: 'Entitlement specification',
        SPECIALIZATION_OF: 'Specialization of',
        EIDAS_LEGAL_IDENTIFIER: 'EIDAS legal identifier',
        REGISTRATION: 'Registration',
        PREFERRED_NAME: 'Preferred name',
    },
    CREDENTIAL_SUBJECT: {
        TYPE: "Credential Subject Type",
        ID: "Credential Subject ID",
    },
    ISSUER: {
        TYPE: "Issuer Type",
        ID: "Issuer ID",
        URL: "Issuer URL"
    },
    ACHIEVEMENT: {
        TYPE: "Achievement Type",
        URL: "Achievement Url",
        NAME: "Achievement Name",
        DESCRIPTION: "Achievement Description"
    },
    CRITERIA: {
        TYPE: "Criteria Type",
        NARRATIVE: "Criteria Narrative"
    },
    CREDENTIAL_SCHEMA:{
        ID: "Credntial Schema ID",
        TYPE: "Credential Schema Type"
    },
    QR: {
        SCAN_QR_TITLE: "QR code scannen",
        SCAN_QR_TIP: "QR code scannen, um ein Credential zu erhalten oder zu präsentieren"
    }
}
